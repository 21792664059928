.sectionTitle {
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: 120%;
  margin: 0;
  position: relative;
  white-space: nowrap;
}

.socialLogin {
  justify-content: flex-start;

  border-radius: var(--dimensions-radius-xxl);
  border: 1px solid var(--colors-neutral-gray-300);
  box-sizing: border-box;
  flex-direction: row;
  padding: 30px 51px;
  gap: 63.5px;

  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.loginDivider {
  height: 443px;
  width: 1px;
  position: relative;
  border-right: 1px solid var(--colors-neutral-black);
  box-sizing: border-box;
}

.textLoginDivider {
  display: none;
}

#createAccount {
  display: none;
}

@media screen and (max-width: 980px) {
  /* .brandLogin {
    align-self: center;
    max-width: 360px;
    padding: 20px 20px;
  }

  .brandLoginContent {
    gap: 55px;
  } */

  .loginDivider {
    border-bottom: 1px solid var(--colors-neutral-gray-300);
    box-sizing: border-box;
    height: 1px;
    position: relative;
    width: 100%;
  }

  .socialLogin {
    border: initial;
    flex-direction: column-reverse;
    padding: 0px;
    align-items: center;
  }

  .textLoginDivider {
    display: initial;
    position: absolute;
    font-weight: 400;
    font-size: 14px;
    color: var(--colors-neutral-gray-300);
    width: 50px;
    top: -10px;
    left: calc(50% - 25px);
    text-align: center;
    background-color: white;
  }

  #createAccount {
    display: block;
  }
}
