.interfaceBack {
    width: 24px;
    height: 24px;
    position: relative;
}

.headerBack {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xs) 0 0;
}

.button {
    position: relative;
    line-height: 150%;
    font-weight: 500;
    display: inline-block;
    min-width: 31px;
}

.divider {
    height: 1px;
    width: 34px;
    position: relative;
    border: 1px solid var(--colors-neutral-gray-500);
    box-sizing: border-box;
    display: none;
}

.content {
    padding: 4.5px 0;
}

.buttonTertiary,
.content,
.header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.buttonTertiary {
    padding: var(--dimensions-spacing-nano);
    box-sizing: border-box;
    min-height: var(--dimensions-spacing-sm);
}

.header {
    align-self: stretch;
    padding: var(--padding-5xs) var(--padding-base) var(--padding-7xs);
    text-decoration: none;
    color: inherit;
}

.main {
    display: flex;
    align-items: flex-start;
    max-width: 100%;

    align-self: stretch;
    border-bottom: 1px solid var(--colors-neutral-gray-200);
    color: var(--colors-neutral-gray-600);
    font-family: var(--body-medium-sm);

    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
    text-align: center;
    font-size: var(--body-medium-sm-size);
}

@media screen and (max-width: 925px) {
  .main {
      gap: var(--gap-xl);
      }
}

@media screen and (max-width: 980px) {
    .main {
        position: absolute;
        width: 100%;
        padding-bottom: 10px;
    }

    .buttonTertiary {
        display: none;
    }

    .interfaceBack {
        width: 32px;
        height: 32px;
        position: relative;
    }
}