.emailLogin {
  justify-content: flex-start;

  width: 341px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-13xl);
  max-width: 100%;
  flex-shrink: 0;
}

.entreOuCadastreSe {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  font-weight: 500;
  font-family: inherit;
  white-space: nowrap;
}

.emailLoginForm {
  display: flex;
  align-items: flex-start;

  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-sm);

  justify-content: flex-start;
}

.loginFormFields {
  display: flex;
  align-items: flex-start;

  margin: 0;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}

.loginButtons {
  display: flex;
  align-items: center;
}

.loginButtons {
  align-self: stretch;
}
.loginButtons {
  flex-direction: column;
}

.emailLoginForm,
.loginFormFields {
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: 450px) {
  .emailLogin {
    gap: var(--gap-base);
  }
}


@media screen and (max-width: 980px) {

  .entreOuCadastreSe{
    display: none;
  }

  .emailLoginForm {
    padding: 0px;
  }

  .emailLogin {
    width: 100%;
  }

  .loginButtons {
    flex-direction: column-reverse;
    gap: 10px;
  }

  #createAccount {
    display: none;
  }
}