.socialLoginButtonHolder {
  width: 356px;
  flex-direction: column;
  gap: 32px;
}

.socialLoginContent,
.socialLoginDescription,
.socialLoginButtonHolder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.socialLoginDescription {
  flex-direction: column;
  font-size: var(--body-bold-md-size);
}
.socialLoginContent {
  flex-direction: column;
  gap: var(--gap-13xl);
  flex-shrink: 0;
}
.entrarComRede {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  font-weight: 500;
  font-family: inherit;
  white-space: nowrap;
}
.socialLoginHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.socialLoginButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.associeUmaConta {
  position: relative;
  line-height: 150%;
}

.associateDescription {
  text-align: left;
  margin-bottom: 20px;
  white-space: break-spaces;
}

.backgroundBlur,
.bottomSheet,
.closeX,
.dragbar {
  display: none;
}

.associate.socialLoginContent {
  width: 356px;
  position: relative;
}

.associate .socialLoginButtonHolder {
  display: none;
}

.bottomSheet.associate {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: var(--gap-13xl);
  font-size: 24px;
  width: 364px;
  top: -5px;
  left: -3px;
}

.associate .bottomSheetTitle {
  font-size: 32px;
  font-weight: 500;
  margin: 0;
}

.associate .bottomSheetContent,
.associate .bottomSheetContent h2 {
  font-size: 14px;
  font-weight: normal;
}

.associate .bottomSheetContent h2 {
  font-weight: bold;
}

.bottomSheet .bottomSheetDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 980px) {
  .socialLoginHeader {
    display: none;
  }

  .socialLoginDescription {
    display: none;
  }

  .socialLoginButtons {
    flex-direction: row;
  }

  .associeUmaConta {
    font-weight: normal;
    width: 100%;
  }

  .associate .socialLoginButtonHolder {
    display: flex;
  }

  .bottomSheet {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 380px;

    flex-direction: column;
    gap: var(--gap-13xl);
    flex-shrink: 0;
  }

  .bottomSheet.associate {
    animation: fadein 1s ease-in forwards;
  }

  @keyframes fadein {
    0% {
      bottom: -220px;
    }
    100% {
      bottom: -1px;
    }
  }

  .bottomSheet.associate {
    display: block;
    width: calc(100% - 40px);
    font-size: medium;
    font-weight: bold;
    position: fixed;
    background-color: var(--colors-neutral-white);
    z-index: 5100;
    padding: 0px 20px 20px 20px;
    bottom: 0;
    border-radius: var(--dimensions-radius-xxl) var(--dimensions-radius-xxl) 0px
      0px;
    left: initial;
    top: initial;
  }

  .bottomSheet .bottomSheetHeader,
  .bottomSheet .bottomSheetDescription {
    display: flex;
    flex-direction: column;
    color: var(--colors-neutral-gray-500);
  }

  .bottomSheet .bottomSheetContent h2 {
    font-weight: normal;
    font-size: var(--body-bold-md-size);
    text-align: left;
    margin: 20px 0;
  }

  .associate .bottomSheetTitle {
    color: var(--colors-neutral-black);
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  .associateDescription {
    width: initial;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 30px;
  }

  .backgroundBlur.associate {
    display: block;
    margin: 10px;
    position: fixed;
    top: -11px;
    left: -11px;
    height: 100vh;
    width: 100vw;
    z-index: 5000;
    background-color: rgba(0, 0, 0, 70%);
  }

  .closeX {
    display: inline-block;
    background-image: url('/public/close-bottom-sheet.png');
    background-position: 100%;
    background-color: white;
    position: absolute;
    border: 0;
    height: 18px;
    width: 18px;
    right: 22px;
    cursor: pointer;
  }

  .dragbar {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .dragbarGrab {
    background-color: var(--colors-neutral-gray-200);
    border: 1px solid var(--colors-neutral-gray-200);
    border-radius: var(--dimensions-radius-pills);
    width: 40px;
    height: 2px;
  }
}
