.error {
    display: flex;
    padding: var(--dimensions-spacing-nano) 0 0;
    align-items: center;
    gap: var(--dimensions-spacing-nano);
    align-self: stretch;
    /* margin-top: 7px; */
}

.error .interface-info .icon {
    font-size: 37%;
    font-weight: bold;
    left: 6px;
    top: 0px;
    position: absolute;
}

.error .interface-info {
    border-radius: 32px;
    height: 16px;
    position:relative;
    width: 16px;

    background-color: var(--color-feedback-danger-200);
    color: white;
}

.error .text {
    color: var(--color-feedback-danger-300);
    font-family: var(--body-medium-sm);
    font-size: var(--body-medium-sm-size);
    font-style: normal;
    font-weight: 500;
    line-height: 150%;

    height: var(--dimensions-spacing-xxxs);
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
}