.socialLogin {
  justify-content: flex-start;

  border-radius: var(--dimensions-radius-xxl);
  border: 1px solid var(--colors-neutral-gray-300);
  box-sizing: border-box;
  flex-direction: row;
  padding: 30px 51px;
  gap: 63.5px;

  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.loginDivider {
  height: 980px;
  width: 1px;
  position: relative;
  border-right: 1px solid var(--colors-neutral-black);
  box-sizing: border-box;
}

.textLoginDivider {
  display: none;
}

.registration {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.acceptRegistration {
  display: inline-grid;
  width: 100%;
  color: var(--colors-neutral-gray-500);
  font-family: var(--body-medium-sm);
  font-size: var(--body-medium-lg-size);
  line-height: 21px;
}

.acceptRegistration {
  color: var(--colors-neutral-gray-400);
  font-size: var(--body-medium-md-size);
  line-height: 18px;
}

.acceptRegistration span {
  display: inline;
}

.acceptRegistration span a,
.acceptRegistration span a:active,
.acceptRegistration span a:visited,
.acceptRegistration span a:hover {
  font-weight: 500;
  color: inherit;
}

.personType {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.personType input {
  margin-bottom: 1px solid black;
}

.personLabel {
  position: static;
  line-height: 1.3em;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 1.1em;
  color: var(--colors-neutral-gray-500);
  font-size: 14px;
}

.personType input[type='radio'] {
  /* clean */
  --webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  /* custom uncheck */
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  /* grid */
  display: grid;
  place-content: center;
}

.personType input[type='radio']::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  /* Windows High Contrast Mode */
  background-color: white;
}

.personType input[type='radio']:checked::before {
  border: 0.5em solid var(--colors-support-200);
}

.acceptNews {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.acceptNews div,
.acceptNews label {
  position: static;
  line-height: 1.3em;
  display: flex;
  color: var(--colors-neutral-gray-500);
  font-size: 14px;
  gap: 18px;
}

.acceptNews span {
  display: inline;
}

.acceptNewsLinked a,
.acceptNewsLinked a:active,
.acceptNewsLinked a:hover,
.acceptNewsLinked a:visited {
  color: inherit;
  font-weight: 500;
}

.acceptNews input[type='checkbox'] {
  --webkit-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  margin: 0;
  color: currentColor;
  display: grid;
  font: inherit;
  place-content: center;
}

.acceptNews input[type='checkbox']::before {
  border-radius: 4px;
  border: 2px solid black;
  background-color: white;
  color: #fff;
  content: '';
  font-size: x-large;
  height: 24px;
  width: 24px;
}

.acceptNews input[type='checkbox']:checked::before {
  background: url('./check.svg') 50% no-repeat;
  background-color: var(--colors-support-200);
  border-color: #0000;
  background-size: 13px;
}

.acceptNews input[type='checkbox' i]:focus-visible {
  outline-style: none;
}

.formNatural {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.backLogin {
  color: var(--colors-neutral-gray-500);
  font-size: var(--body-medium-lg-size);
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  white-space: nowrap;
}

.backLogin a,
.backLogin a:active,
.backLogin a:visited,
.backLogin a:hover {
  font-weight: 500;
  color: inherit;
}

.stateRegistration {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.stateRegistration label.acceptNews {
  align-items: start;
  display: flex;
  padding: 5px;
  text-wrap: nowrap;
  gap: 10px;
}

.stateRegistration .acceptNews span {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--colors-neutral-gray-500);
}

@media screen and (max-width: 980px) {
  .socialLogin {
    border: initial;
    flex-direction: column-reverse;
    padding: 0px;
    align-items: center;
  }

  .loginDivider {
    border-bottom: 1px solid var(--colors-neutral-gray-300);
    box-sizing: border-box;
    height: 1px;
    position: relative;
    width: 100%;
  }

  .textLoginDivider {
    display: initial;
    position: absolute;
    font-weight: 400;
    font-size: 14px;
    color: var(--colors-neutral-gray-300);
    width: 50px;
    top: -10px;
    left: calc(50% - 25px);
    text-align: center;
    background-color: white;
  }
}
