.inputTextContent {
  width: 100%;
  display: inline-grid;
}
.inputText {
  display: flex;
  align-items: flex-start;
}
.inputText {
  align-self: stretch;
  border-radius: var(--dimensions-radius-lg);
  background-color: var(--colors-neutral-white);
  border: 1px solid var(--colors-neutral-gray-200);
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--padding-sm) var(--padding-mini);
  gap: 12px;
  position: relative;
}

.error {
  border-color: var(--color-feedback-danger-200);
}

.inputText input {
  height: 32px;
  position: relative;
}
.inputText input {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: var(--body-medium-sm);
  font-size: var(--body-medium-lg-size);
  background-color: transparent;
  flex: 1;
  line-height: 150%;
  color: var(--colors-neutral-gray-400);
  text-align: left;
  display: flex;
  align-items: center;
  min-width: 168px;
  white-space: nowrap;
  padding: 0;
  margin-top: 6px;
}

.inputText input[type='select'] {
  cursor: pointer;
}

.labelPointer {
  cursor: pointer;
}
.labelAuto {
  cursor: auto;
}

.inputText label {
  font-family: var(--body-medium-sm);
  font-size: var(--body-medium-lg-size);
  color: var(--colors-neutral-gray-400);
  position: absolute;
  /* transform: translate(0, 26px) scale(1); */
  transform-origin: top left;
  transition: all 0.2s ease-out;
  margin-top: 8px;
}

.inputText .icon {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 var(--padding-base);
  right: 0;
}

.inputText .interfaceEye {
  height: 32px;
  width: 32px;
  position: relative;
  cursor: pointer;
}

.inputText .interfaceEyeOpen {
  height: 32px;
  width: 32px;
  position: relative;
  cursor: pointer;
}

.inputText .interfaceEyeOpen path {
  fill: var(--colors-neutral-black);
}

.inputText .interfaceEye:hover path {
  fill: var(--colors-neutral-black);
}

.inputText .icon .selectArrow {
  border: none;
  background: url('./arrow-up.svg') no-repeat center center;
  transform: rotate(180deg);
  cursor: pointer;
  height: 32px;
  position: relative;
  width: 32px;
}

.inputText .icon .selectArrow.down {
  transform: rotate(0deg);
}

.inputText:focus-within,
.inputText.active {
  gap: initial;
}

.inputText:focus-within label,
.inputText.active label {
  transform: translate(0, -10px) scale(0.75);
}

.inputText.active.error label {
  color: red;
}

.inputHints {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.hints {
  display: flex;
  flex-direction: column;
  gap: var(--dimensions-spacing-xxxs);
  position: absolute;
  width: 98%;
  margin-top: 57px;
  margin-left: -17px;

  z-index: 1000;
  font-size: 12px;
  padding: 5px;
  background-color: white;
  border-radius: var(--dimensions-radius-lg);
  max-height: 260px;
}

.hints .hints-content {
  overflow-y: auto;
  /* Shadow/SM */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: var(--dimensions-radius-lg);
}

.hints .hint {
  display: flex;
  padding: var(--dimensions-spacing-xxxs);
  align-items: center;
  gap: var(--dimensions-spacing-xxxs);
  align-self: stretch;

  border-bottom: 1px solid var(--colors-neutral-gray-200);
  background: var(--colors-neutral-white);
}

.hints .hint:first-child {
  border-radius: var(--dimensions-radius-lg) var(--dimensions-radius-lg) 0px 0px;
  border-bottom: 1px solid var(--colors-neutral-gray-200);
}

.hints .hint:last-child {
  border-radius: 0px 0px var(--dimensions-radius-lg) var(--dimensions-radius-lg);
  border-top: 1px solid var(--colors-neutral-gray-200);
}

.hints .hint:hover {
  background-color: var(--colors-neutral-gray-100);
  cursor: pointer;
}

.c__cellphone {
  display: none;
}
