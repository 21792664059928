.loginEntrarFbDesktopBr01 {
  background-color: var(--colors-neutral-white);
  display: flex;
  flex-direction: column;

  width: 100%;
  position: relative;
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3vh;
  line-height: normal;
  letter-spacing: normal;
}

.container {
  width: 100%;
  position: relative;
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 106px;
  line-height: normal;
  letter-spacing: normal;
}

.main {
  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;
  text-align: center;
  font-size: var(--body-medium-sm-size);
}

.brandLogin {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  min-width: 360px;

  align-self: stretch;
  color: var(--colors-neutral-gray-600);
  font-family: var(--body-medium-sm);

  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--heading-medium-lg-size);
}

.brandLoginContent {
  display: flex;
  align-items: flex-start;
  max-width: 100%;

  width: 936px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 86px;
}

/* .socialLogin {
  justify-content: flex-start;

  border-radius: var(--dimensions-radius-xxl);
  border: 1px solid var(--colors-neutral-gray-300);
  box-sizing: border-box;
  flex-direction: row;
  padding: 30px 51px;
  gap: 63.5px;

  display: flex;
  align-items: flex-start;
  max-width: 100%;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media screen and (max-width: 925px) {
  .loginEntrarFbDesktopBr01 {
    gap: 53px;
  }

  .brandLoginContent {
    gap: 43px;
  }
}

@media screen and (max-width: 450px) {
  .loginEntrarFbDesktopBr01 {
    gap: 26px;
  }
}

@media screen and (max-width: 980px) {
  .brandLogin {
    align-self: center;
    max-width: 360px;
    padding: 20px 20px;
  }

  .brandLoginContent {
    gap: 55px;
  }

  /* .socialLogin {
    border: initial;
    flex-direction: column-reverse;
    padding: 0px;
    align-items: center;
  } */
}
