.buttonTertiary1,
.content2,
.loginButtons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content2 {
  flex: 1;
  flex-direction: column;
  padding: 1px 0;
}

a.buttonTertiary1 {
  justify-content: center;
}

a .content2 {
  flex: 0;
}

.buttonTertiary1 {
  flex-direction: row;
  padding: var(--dimensions-spacing-xxxs) 0px;
  box-sizing: border-box;
  min-height: var(--dimensions-spacing-lg);
  text-decoration: none;
  color: inherit;
}

.tertiaryLinkButton {
  border: none;
  background: none;
  cursor: pointer;
  padding: var(--dimensions-spacing-xxxs) 0px;
  display: block;
}

.button2 {
  position: relative;
  font-size: var(--body-medium-lg-size);
  line-height: 150%;
  font-weight: 500;
  font-family: var(--body-medium-sm);
  color: var(--colors-neutral-gray-500);
  text-align: center;
  white-space: nowrap;
}

.divider1 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-bottom: 1px solid var(--colors-neutral-gray-500);
  box-sizing: border-box;
}

@media screen and (max-width: 980px) {
  .divider1 {
    display: none;
  }

  .button2 {
    border-bottom: 1px solid var(--colors-neutral-gray-500);
  }

  .content2 {
    align-items: center;
  }

  .buttonTertiary1 {
    padding: initial;
  }
}
