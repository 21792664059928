.notification {
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--dimensions-radius-lg);
  display: flex;
  gap: var(--dimensions-spacing-xxxs);
  overflow: hidden;
  padding: var(--dimensions-spacing-xxxs);
  position: relative;
}

.notification .interface-info {
  border-radius: 32px;
  height: 24px;
  position: relative;
  width: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notification .icon {
  position: absolute;
  font-size: 50%;
  line-height: 24px;
}

.notification .content {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--dimensions-spacing-nano);
  position: relative;
  /* padding-top: 7px; */
}

.notification .label-title {
  align-self: stretch;
  font-family: var(--body-medium-sm);
  font-size: var(--body-medium-md-size);
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 15px */
  /* margin-top: -1px; */
  position: relative;
}

.notification .label {
  align-self: stretch;
  font-family: var(--body-medium-sm);
  font-size: var(--body-medium-sm-size);
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 15px */
  position: relative;
}

.notification .link {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  height: 16px;
  justify-content: center;
  position: relative;
}

.notification .text-wrapper {
  flex: 1;
  font-family: var(--body-medium-sm);
  font-size: var(--body-medium-lg-size);
  font-style: normal;
  font-weight: 700;
  line-height: var(--body-bold-md-size);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowap;
  width: 21px;
}

.notification .class {
  align-self: stretch !important;
  border-color: var(--color-feedback-positive-400) !important;
  width: 100% !important;
}

.notification .class-2 {
  align-self: stretch !important;
  border-color: var(--color-feedback-danger-400) !important;
  width: 100% !important;
}

.notification .class-3 {
  align-self: stretch !important;
  border-color: var(--colors-support-400) !important;
  width: 100% !important;
}

.notification.error {
  background-color: var(--color-feedback-danger-100);
  color: var(--colors-neutral-white);
}

.notification.info {
  color: var(--colors-support-400);
  background-color: var(--colors-support-100);
}

.notification.success {
  background-color: var(--color-feedback-positive-100);
}

.notification.error .interface-info {
  background-color: var(--color-feedback-danger-200);
}

.notification.info .interface-info {
  background-color: var(--colors-support-200);
  color: var(--colors-neutral-white);
}

.notification.success .interface-info {
  background-color: var(--color-feedback-positive-200);
}

.notification.error .label-title {
  color: var(--color-feedback-danger-400);
}

.notification.info .label-title {
  color: var(--colors-support-400);
}

.notification.success .label-title {
  color: var(--color-feedback-positive-400);
}

.notification.error .label-title {
  color: var(--color-feedback-danger-400);
}

.notification.error .label {
  color: var(--color-feedback-danger-400);
}

.notification.info .label {
  color: var(--colors-support-400);
}

.notification.success .label {
  color: var(--color-feedback-positive-400);
}

.notification.error .text-wrapper {
  color: var(--color-feedback-danger-400);
}

.notification.info .text-wrapper {
  color: var(--colors-support-400);
}

.notification.success .text-wrapper {
  color: var(--color-feedback-positive-400);
}

@media screen and (max-width: 980px) {
  .notification.animateMobile {
    animation: fadein 2s ease-out forwards;
  }

  @keyframes fadein {
    0% {
      top: -40px;
    }
    100% {
      top: 30px;
    }
  }

  .notification.animateMobile {
    position: fixed;
    width: 288px;
    z-index: 1000;
    align-items: center;
  }

  .notification.error {
    background-color: rgba(0, 0, 0, 65%);
  }

  .notification.error .label,
  .notification.error .label-title {
    color: var(--colors-neutral-white);
  }

  .notification.error .interface-info {
    border: 1px solid var(--colors-neutral-white);
    background-color: rgba(0, 0, 0, 0);
  }
}
