/* Popup.css */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 744px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-popup-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  background-color: transparent;
  background-image: url('/public/icon-close-popup.png');
  background-repeat: no-repeat;
  background-position: center center;
  height: 32px;
  width: 32px;
}

.open-popup-button {
  padding: 10px 20px;
  background-color: #6200ea;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 980px) {
  .popup-content {
    position: fixed;
    padding-top: 56px;
    padding-bottom: 0px;
    align-items: center;
    top: 0;
    bottom: 0;
    max-width: 100%;
    display: flex;
    min-width: 360px;
    flex-direction: column;
  }

  .close-popup-button {
    left: 50px;
    top: 20px;
    right: auto;
    background-image: url('/public/interface--back.svg');
    background-size: contain;
  }
}

@media screen and (max-width: 460px) {
  .popup-content {
    align-items: flex-start;
  }
}
