.accountexists {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.accountexists h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
}

.accountexists p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.accountexists .form {
  width: 552px;
  text-align: left;
  display: flex;
  gap: 20px;
}

.accountexists button {
  width: 100%;
  margin: 24px 0px;
  align-self: center;
}

footer {
  display: none;
}

@media screen and (max-width: 980px) {
  .accountexists {
    align-items: flex-start;
    text-align: left;
    padding: var(--dimensions-spacing-nano) var(--dimensions-spacing-xxs);
    height: 100%;
    max-width: 360px;
  }

  .accountexists h1 {
    font-weight: 500;
  }

  .accountexists p {
    font-size: 12px;
    line-height: 18px;
    color: var(--colors-neutral-gray-400);
  }

  .accountexists .form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .accountexists .form button {
    bottom: 10px;
    position: fixed;
    width: 90%;
    max-width: 360px;
    margin: 0px;
    display: block;
  }

  .accountexists p br {
    display: none;
  }
}

@media screen and (max-width: 460px) {
  .accountexists {
    max-width: none;
    width: 90%;
  }
  .accountexists .form button {
    width: 90%;
    max-width: none;
  }
}
