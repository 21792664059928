

.socialFacebook {
  height: 32px;
  width: 32px;
  position: relative;
}
.loginComFacebook {
  position: relative;
  font-size: var(--body-medium-lg-size);
  line-height: 150%;
  font-weight: 500;
  font-family: var(--body-medium-sm);
  color: var(--colors-neutral-gray-600);
  text-align: center;
}
.buttonSecondary {
    cursor: pointer;
    border: 0;
    padding: var(--padding-xs) 63px;
    background-color: var(--color-whitesmoke-100);
    border-radius: var(--dimensions-radius-pills);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    gap: var(--dimensions-spacing-nano);
    min-height: var(--dimensions-spacing-lg);
    width: 312px;
    white-space: nowrap;
  }
  .buttonSecondary:hover {
    background-color: var(--color-gainsboro);
  }
  .socialApple {
    height: 32px;
    width: 32px;
    position: relative;
    object-fit: cover;
  }
  .loginComApple {
    position: relative;
    font-size: var(--body-medium-lg-size);
    line-height: 150%;
    font-weight: 500;
    font-family: var(--body-medium-sm);
    color: var(--colors-neutral-gray-600);
    text-align: center;
    display: inline-block;
    min-width: 118px;
  }
  .socialGoogle {
    height: 32px;
    width: 32px;
    position: relative;
  }
  .loginComGoogle {
    position: relative;
    font-size: var(--body-medium-lg-size);
    line-height: 150%;
    font-weight: 500;
    font-family: var(--body-medium-sm);
    color: var(--colors-neutral-gray-600);
    text-align: center;
    display: inline-block;
    min-width: 128px;
  }
  
  @media screen and (max-width: 450px) {
    .buttonSecondary {
      padding-left: var(--padding-xl);
      padding-right: var(--padding-xl);
      box-sizing: border-box;
    }
  }

  @media screen and (max-width: 980px) {
    .loginComGoogle {
      display: none;
    }

    .loginComFacebook {
      display: none;
    }

    .loginComApple {
      display: none;
    }

    .buttonSecondary {
      width: 64px;
      height: 64px;
      padding: 0px;
    }

  }