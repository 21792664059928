.brandNameContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
}

.dafitiBlackPositive {
  height: 80px;
  width: 200px;
  position: relative;
}

.brandLink {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 980px) {
  .brandNameContainer {
    padding: 0 var(--padding-xl);
  }

  .dafitiBlackPositive {
    height: 32px;
    width: 80px;
  }
}
