.contentBox {
  border: 1px solid var(--colors-neutral-gray-300);
  border-radius: var(--dimensions-radius-xxl);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 30px 51px;
  width: 936px;
}

.sectionTitle {
  margin: 0;
  font-weight: 500;
  line-height: 120%;
  position: relative;
  white-space: nowrap;
  font-family: inherit;
  color: var(--colors-neutral-gray-600);
  font-size: var(--heading-medium-lg-size);
}

.resetPass .loginFormFields {
  align-items: flex-start;
  gap: 20px;
  display: flex;
  flex-direction: column;
  width: 312px;
}

.passRules {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 8px;
}

.valid.checkStatus {
  background-image: url('/public/pass-ok.png');
  color: var(--color-feedback-positive-300);
}

.error.checkStatus {
  background-image: url('/public/pass-fail.png');
  color: var(--color-feedback-danger-300);
}

.checkStatus {
  background-image: url('/public/pass-loading.png');
  background-repeat: no-repeat;
  background-position: left center;
  line-height: 32px;
  padding-left: 32px;
  font-size: 12px;
}

@media screen and (max-width: 980px) {
  .contentBox {
    align-items: center;
    border: initial;
    flex-direction: column;
    padding: 0;
    width: 100%;
  }

  .contentBox h1 {
    display: none;
  }

  .resetPass .loginFormFields {
    width: 100%;
  }
}
