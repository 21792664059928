@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
body {
  margin: 0;
  line-height: normal;
}
:root {
  --form-control-color: rebeccapurple;

  /* fonts */
  --body-medium-sm: Poppins;

  /* font sizes */
  --body-medium-sm-size: 10px;
  --body-medium-md-size: 12px;
  --body-medium-lg-size: 14px;
  --body-bold-md-size: 12px;
  --heading-medium-lg-size: 32px;

  /* Colors */
  --colors-neutral-white: #fff;
  --colors-neutral-black: #000;
  --colors-neutral-gray-100: #f7f7f7;
  --colors-neutral-gray-200: #ececec;
  --colors-neutral-gray-300: #ccc;
  --colors-neutral-gray-400: #888;
  --colors-neutral-gray-500: #666;
  --colors-neutral-gray-600: #2d2d2d;
  --color-darkgray-100: #b3b3b3;
  --color-whitesmoke-100: #f7f7f7;
  --color-gainsboro: #dedede;
  --color-feedback-danger-100: #ffdddf;
  --color-feedback-danger-200: #fe444d;
  --color-feedback-danger-300: #d8010c;
  --color-feedback-danger-400: #ae0a16;
  --colors-support-100: #dcddff;
  --colors-support-200: #6769fd;
  --colors-support-400: #2628ae;

  --color-feedback-positive-100: rgb(7, 156, 20);
  --color-feedback-positive-200: rgb(7, 156, 20);
  --color-feedback-positive-300: rgba(23, 132, 57, 1);
  --color-feedback-positive-400: rgb(7, 156, 20);

  /* Spacing */
  --dimensions-spacing-none: 0px;
  --dimensions-spacing-xxs: 24px;
  --dimensions-spacing-xxxs: 16px;
  --dimensions-spacing-quark: 4px;
  --dimensions-spacing-md: 48px;
  --dimensions-spacing-nano: 8px;
  --dimensions-spacing-lg: 56px;
  --dimensions-radius-xxl: 24px;
  --dimensions-radius-pills: 480px;
  --dimensions-radius-lg: 8px;
  --dimensions-spacing-sm: 40px;

  /* Gaps */
  --gap-5xl: 24px;
  --gap-9xs: 4px;
  --gap-base: 16px;
  --gap-xl: 20px;
  --gap-5xs: 8px;
  --gap-13xl: 32px;

  /* Paddings */
  --padding-base: 16px;
  --padding-xl: 20px;
  --padding-sm: 14px;
  --padding-5xl: 24px;
  --padding-6xl: 25px;
  --padding-7xs: 6px;
  --padding-mini: 15px;
  --padding-xs: 12px;
  --padding-5xs: 8px;

  /* Border radiuses */
  --br-461xl: 480px;
  --br-5xs: 8px;
}
