.passRules {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 8px;
}

.valid.checkStatus {
  background-image: url('/public/pass-ok.png');
  color: var(--color-feedback-positive-300);
}

.error.checkStatus {
  background-image: url('/public/pass-fail.png');
  color: var(--color-feedback-danger-300);
}

.checkStatus {
  background-image: url('/public/pass-loading.png');
  background-repeat: no-repeat;
  background-position: left center;
  line-height: 32px;
  padding-left: 32px;
  font-size: 12px;
}
