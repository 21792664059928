.button1 {
  position: relative;
  font-size: var(--body-medium-lg-size);
  line-height: 150%;
  font-weight: 500;
  font-family: var(--body-medium-sm);
  color: var(--colors-neutral-gray-600);
  text-align: center;
  display: inline-block;
  min-width: 80px;
}
.button1.submit {
  color: var(--colors-neutral-white);
}

.disableTextButton {
  color: var(--colors-neutral-gray-400);
}



.buttonPrimary {
  cursor: pointer;
  border: 1px solid var(--colors-neutral-gray-300);
  padding: var(--padding-base) var(--padding-xl);
  background-color: transparent;
  align-self: stretch;
  border-radius: var(--dimensions-radius-pills);
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  min-height: var(--dimensions-spacing-lg);
  white-space: nowrap;
}

.buttonPrimary:hover {
  background-color: rgba(179, 179, 179, 0.09);
  border: 1px solid var(--color-darkgray-100);
  box-sizing: border-box;
}

.buttonPrimary.submit {
  background-color: var(--colors-neutral-gray-600);
  color: var(--color-neutral-white);
}

.disabledButton {
  padding: 17.5px var(--padding-xl);
  background-color: var(--colors-neutral-gray-300);
  border: 0px;
}

.button1.disableTextButton.submit {
  color: var(--colors-neutral-gray-400);
}

.disabledButton.submit {
  background-color: var(--colors-neutral-gray-300);
  color: var(--colors-neutral-gray-400);
}

.disabledButton:hover {
  background-color: var(--color-darkgray-100);
  border: 0px;
}