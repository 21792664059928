.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
}

.loading img {
    position: fixed;
    left: calc(50vw - 37px);
    top: calc(50vh - 26px);
}