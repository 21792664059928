.footerBrazil,
.loginEntrarFbDesktopBr01 {
  background-color: var(--colors-neutral-white);
  display: flex;
  flex-direction: column;
}

.footerBrazil {
  align-self: stretch;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--dimensions-spacing-xxxs);
  box-sizing: border-box;
  gap: var(--dimensions-spacing-xxs);
  max-width: 100%;
  text-align: left;
  font-size: var(--body-bold-md-size);
  color: var(--colors-neutral-black);
  font-family: var(--body-medium-sm);
}

.container {
  align-self: stretch;
  border-top: 1px solid var(--colors-neutral-gray-200);
  border-bottom: 1px solid var(--colors-neutral-gray-200);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-sm) var(--dimensions-spacing-md);
  position: relative;
  gap: var(--gap-base);
  max-width: 100%;
}

.container {
  padding-left: var(--dimensions-spacing-md);
  padding-right: var(--dimensions-spacing-md);
  box-sizing: border-box;
}

.pagamento,
.paymentWrapper {
  justify-content: flex-start;
  max-width: 100%;
}

.paymentWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pagamento {
  width: 952px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  z-index: 0;
}

.pagamentoFirst {
  width: 98px;
  position: relative;
  line-height: 17px;
  display: flex;
  align-items: center;
}

.iconsPaymentBarCode {
  overflow: hidden;
  flex-shrink: 0;
}

.iconsPaymentBarCode,
.paymentPix {
  height: 32px;
  width: 32px;
  position: relative;
  min-height: 32px;
}

.iconsPaymentAmericanExp,
.iconsPaymentElo,
.iconsPaymentItau,
.iconsPaymentMaestro,
.iconsPaymentMastercardB,
.iconsPaymentVisaBlack,
.iconsPaymentVisaBlackN {
  height: 32px;
  width: 32px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 32px;
}

.payment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--dimensions-spacing-nano);
  max-width: 100%;
}

.supportInfo {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  gap: var(--gap-xl);
}

.gfgHorizontalPositive {
  height: 40px;
  width: 138px;
  z-index: 1;
}

.ajudaESuporte {
  height: 14px;
  position: relative;
  line-height: 150%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  min-width: 98px;
}

.supportLink {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
}

.polticaDePrivacidade,
.polticaDeTrocas {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}

.footerLinks,
.policyLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.policyLinks {
  align-self: stretch;
  gap: var(--gap-9xs);
  font-size: var(--body-medium-sm-size);
  color: var(--colors-neutral-gray-400);
}

.footerLinks {
  width: 220px;
  gap: var(--gap-5xs);
}

.contactInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}

.supportInfo {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  gap: var(--gap-xl);
}

.atendimentoCallContainer {
  position: relative;
  font-size: var(--body-medium-sm-size);
  line-height: 140%;
  color: var(--colors-neutral-gray-400);
}

.centralDeAtendimento {
  position: relative;
  line-height: 150%;
}

.atendimento,
.span1 {
  font-weight: 500;
}

.span {
  color: var(--colors-neutral-gray-500);
}

.security {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  gap: var(--dimensions-spacing-quark);
  max-width: 100%;
  font-size: var(--body-medium-sm-size);
  color: var(--colors-neutral-gray-400);
}

.seguranaECertificado {
  position: relative;
  line-height: 150%;
  display: inline-block;
  color: var(--colors-neutral-black);
  min-width: 127px;
}
.image12Icon {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 4px;
  object-fit: cover;
}

.homologadoPelaUsertrust,
.siteSeguroCom {
  position: relative;
  line-height: 150%;
  font-weight: 500;
}

.AllRightsReserved {
  display: none;
}

@media screen and (max-width: 980px) {
  .container {
    display: none;
  }

  .security {
    display: none;
  }

  .AllRightsReserved {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: var(--dimensions-spacing-xxs);
  }

  .gfgHorizontalPositive {
    width: 55px;
    height: 16px;
  }

  .copyright {
    font-family: var(--body-medium-sm);
    line-height: 15px;
    font-weight: 400;
    color: var(--colors-neutral-gray-500);
    font-size: 10px;
  }

  .copyright a {
    font-weight: 700;
    line-height: 14px;
    text-decoration: none;
    color: var(--colors-neutral-gray-500);
    cursor: pointer;
  }

  .footerBrazil {
    width: 100%;
    border-top: 1px solid lightgray;
    height: 62px;
    padding: initial;
  }

  .pagamento,
  .paymentWrapper {
    width: 100%;
    max-width: initial;
  }

  .supportInfo {
    align-items: flex-start;
    width: 100%;
    max-width: initial;
  }
}
